<template>
  <GModal name="modal" class="modal modal-cancel-shopping">
    <div slot="header">
      <div class="modal-title">
        <div class="text-red">Keluar</div>
        <div class="text">
          <p>Sedikit lagi pesananmu selesai, Bestie!<br />Yakin log out & membatalkan pesanan?</p>
        </div>
      </div>
    </div>
    <div slot="body">
      <div class="logout" @click="$emit('logout')">
        <span>Ya, keluar</span>
      </div>
      <div v-if="cart_qty_total" class="continue-payment" @click="$emit('close')">
        <span>Batalkan</span>
      </div>
    </div>
  </GModal>
</template>

<script>
export default {
  name: 'ModalCancelShopping',

  computed: {
    cart_qty_total() {
      return this.$store.state.vmCola.cart_qty;
    },
  },
};
</script>
